import React,{useState, useEffect} from 'react'
import {AppBar, Typography , Toolbar, Button, Avatar} from '@material-ui/core';
import useStyles from './styles';
import memories from '../../images/memories.png';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import decode from 'jwt-decode';
import {useHistory, useLocation} from 'react-router-dom';
const Navbar =() => {

    const classes = useStyles();
    const [user,setUser]= useState(JSON.parse(localStorage.getItem('profile')));
    console.log(user);
    const dispatch = useDispatch();
    const location = useLocation();
    const history=useHistory();
    const logOut=()=>{
      dispatch({type: 'LOGOUT'});
      history.push('/');
      setUser(null);
    }

    useEffect(()=>{
      const token= user?.token;
      if(token) {
        const decodeToken = decode(token);
        if(decodeToken.exp * 1000 < new Date().getTime())  logOut();

      }
      setUser(JSON.parse(localStorage.getItem('profile')))
    },[location])
    return (
      <AppBar className={classes.appBar} position='static' color='inherit' >
        <div className={classes.brandContainer}>
        <Typography component={Link} to="/" className={classes.heading} variant='h2' align='center'> Memories</Typography>
        <img className={classes.image} src={memories} alt='memories' height='60'></img>
        </div>
        <Toolbar className={classes.toolbar}>
          {user ? (
            <div className={classes.profile}>
                <Avatar className={classes.purple} alt={user.result.name} src={user.result.imageUrl} >{user.result.name.charAt(0)} </Avatar>
                <Typography className={classes.userName} variant="h6" >{user.result.name}</Typography>
                <Button variant="contained" className={classes.logout} color="secondary" onClick={logOut}> Log out</Button>
            </div>
          ) : (
            <Button component={Link} to="/auth" variant="contained" color="primary">Sign In</Button>
          )}
        </Toolbar>
      </AppBar>
    )
}

export default Navbar
