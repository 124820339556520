import React,{useState, useEffect} from 'react';
import useStyles  from './styles';
import FileBase from 'react-file-base64';
import {TextField, Button, Typography, Paper} from '@material-ui/core';
import {useDispatch ,useSelector} from 'react-redux';
import {createPost, updatePost} from '../../actions/posts';
  
const Form =({currentId, setCurrentId}) =>{
    const classes = useStyles();
    const post = useSelector((state)=> currentId? state.posts.find((p)=> p._id === currentId): null)
    const [postData, setPostData] = useState({
        
        title: '',
        message: '',
        tags: '',
        selectedFile: ''
    });
    useEffect(() => {
        if(post) setPostData(post);
        
    }, [post]);
    const user= JSON.parse(localStorage.getItem('profile'))
    const dispatch = useDispatch();

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(currentId === null)
        {
            dispatch(createPost({...postData, name: user?.result?.name }));

        }else{
            
            dispatch(updatePost(currentId, {...postData, name: user?.result?.name }));
        }
        clear();
    }

    const clear =() =>{

        setCurrentId(null);

        setPostData({
           
            title: '',
            message: '',
            tags: '',
            selectedFile: ''
        });

    }
    if(!user?.result?.name)
    {
        return (
            <Paper className={classes.paper}>
                <Typography variant='h6' align='center'>
                    Please Sign in to create your own memories and like other's memories
                </Typography>
            </Paper>
        )
    }
    return (
        <Paper className={classes.paper}>
            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                <Typography variant='h6'>
                    {currentId ? 'Editing': 'Creating' } a Memory
                </Typography>
                
                <TextField 
                name='title'
                variant='outlined' 
                label='Title' 
                fullWidth 
                value={postData.title}
                onChange={(e)=> setPostData({ ...postData, title: e.target.value})}
                />
                <TextField 
                name='message'
                variant='outlined' 
                label='Message' 
                fullWidth 
                value={postData.message}
                onChange={(e)=> setPostData({ ...postData, message: e.target.value})}
                />
                <TextField name="tags" variant="outlined" label="Tags (coma separated)" fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} />
               
                <Button className={classes.buttonSubmit} variant='contained' type='submit' color='primary' size='large' fullWidth>Submit</Button>
                <Button className={classes.buttonSubmit} variant='contained' onClick={clear} color='secondary' size='small' fullWidth>Clear</Button>
            </form>
        </Paper>
    );

}
export default Form;
